<template>
	<section>
		<modal ref="modalCrearVendedor" titulo="Crear vendedor" tamano="modal-xl" no-aceptar adicional="Guardar"
			@adicional="updateDatos">
			<cargando v-if="cargando" />
			<ValidationObserver ref="validacion">
				<div class="row mx-0 justify-center my-3 mb-5">
					<div class="col-auto">
						<p class="text-center text-general f-14 py-1">
							Foto
						</p>
						<slim-cropper ref="foto_cedis" :options="slimOptions" class="border cr-pointer leechero-picture" />
					</div>
				</div>
				<div class="row mx-0 justify-center">
					<div class="col-5">
						<p class="label-mini">
							Nombre
						</p>
						<ValidationProvider v-slot="{ errors }" rules="required|max:20" name="nombre">
							<el-input v-model="model.prop_nombre" class="br-12" placeholder="Ej. Ana Maria"
								maxlength="20" />
							<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<p class="label-mini mt-4">
							Apellido
						</p>
						<ValidationProvider v-slot="{ errors }" rules="required|max:26" name="apellido">
							<el-input v-model="model.prop_apellido" class="br-12" placeholder="Ej. Caicedo Serrano"
								maxlength="20" />
							<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<p class="label-mini f-14 mt-4">Fecha nacimiento</p>
						<ValidationProvider v-slot="{ errors }" rules="required" name="fecha de nacimiento">
							<el-date-picker v-model="model.propietario_nacimiento" placeholder="Fecha Nacimiento"
								type="date" format="dd - MM - yyyy" value-format="yyyy-MM-dd" class="w-100 br-12"
								:picker-options="pickerOptions" />
							<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<div class="row mx-0 mb-5 justify-center">
							<div class="col-auto mt-3 d-middle">
								<input v-model="model.propietario_genero" :value="2" type="radio"
									class="option-input black radio" />
								Mujer
							</div>
							<div class="col-auto mt-3 d-middle">
								<input v-model="model.propietario_genero" :value="1" type="radio"
									class="option-input black radio" />
								Hombre
							</div>
							<div class="col-auto mt-3 d-middle">
								<input v-model="model.propietario_genero" :value="3" type="radio"
									class="option-input black radio" />
								Otro
							</div>
						</div>
						<p class="label-mini pl-0 mb-4">
							Contacto
						</p>
						<p class="label-mini">
							Correo eléctronico
						</p>
						<ValidationProvider v-slot="{ errors }" rules="max:70|email" name="correo">
							<el-input v-model="model.correo" placeholder="Ej. E-mail@correo.com" class="br-12"
								maxlength="70" />
							<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<p class="label-mini mt-4">
							Teléfono
						</p>
						<ValidationProvider v-slot="{ errors }" rules="required|max:20|min:5|numeric|min_value:1"
							name="teléfono">
							<el-input v-model="model.telefono" class="input-number" placeholder="Ej. 54 2352 532 4345"
								maxlength="20">
								<!-- <template slot="prepend">
									<img :src="model.bandera" class="rounded-circle obj-cover" width="28" height="28" />
									<span class="text-general ml-2 f-600">
										+{{ model.indicativo }}
									</span>
								</template> -->
							</el-input>
							<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<div class="label-mini mt-4">
							Cupo del credito
						</div>
						<div class="input-number">
							<template v-if="!model.ilimitado">
								<ValidationProvider v-slot="{ errors }" rules="required" name="Cupo del credito">
									<Money v-model="model.cupo" class="input-money w-100" v-bind="money" />
									<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
								</ValidationProvider>
							</template>
							<template v-else>
								<el-input placeholder="Valor" disabled size="small" />
							</template>
							<el-checkbox v-model="model.ilimitado" @change="!model.ilimitado == esIlimitado()">
								Ilimitado
							</el-checkbox>
						</div>
						<p class="label-mini mt-4">Descripcion (Esta información la podrán ver los clientes)</p>
						<ValidationProvider v-slot="{ errors }" rules="required|max:300" name="descripción cliente">
							<el-input v-model="model.descripcion" type="textarea" :rows="6" maxlength="300" class="w-100"
								placeholder="Describete, cúentale a tus clientes quien eres. como los quieres entender" />
							<span class="text-danger f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<div class="row mx-0 py-4" />
						<div class="row mx-0">
							<el-checkbox v-model="existTienda" class="check-red" />
							<div class="col text-general f-14">
								Tiene una tienda
							</div>
						</div>
						<template v-if="existTienda">
							<div class="row mx-0 my-3">
								<div class="col-auto px-0 d-middle">
									<input v-model="model.tipo" :value="1" type="radio" class="option-input black radio" />
									Persona Natural
								</div>
								<div class="col-auto ml-3 px-0 d-middle">
									<input v-model="model.tipo" :value="2" type="radio" class="option-input black radio" />
									Tienda
								</div>
							</div>
							<div class="row mx-0">
								<template v-if="model.tipo == 1">
									<div class="col-12 my-3 px-0">
										<ValidationProvider v-slot="{ errors }" rules="numeric|max:20" name="NIT">
											<p class="label-mini">Nit de la tienda*</p>
											<el-input v-model="model.nit" maxlength="20" class="w-100" />
											<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
										</ValidationProvider>
										<!-- <el-input v-model="noIdentidad" class="w-100" placeholder="Ej: 123456789" /> -->
									</div>
								</template>
								<template v-else>
									<div class="col pl-0 pr-2 my-3 mr-1">
										<p class="label-mini">Nombre de la tienda</p>
										<ValidationProvider v-slot="{ errors }" rules="max:60" name="NIT">
											<el-input v-model="model.nombre" maxlength="60" class="w-100" />
											<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
										</ValidationProvider>
										<!-- <el-input v-model="noIdentidad" class="w-100" placeholder="Ej: 123456789" /> -->
									</div>
									<div class="col pr-0 pl-2 my-3">
										<p class="label-mini">Nit de la tienda*</p>
										<ValidationProvider v-slot="{ errors }" rules="numeric|max:20" name="NIT">
											<el-input v-model="model.nit" maxlength="20" class="w-100" />
											<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
										</ValidationProvider>
										<!-- <el-input v-model="noIdentidad" class="w-100" placeholder="Ej: 123456789" /> -->
									</div>
								</template>
							</div>
							<p class="label-mini mt-4">
								Archivo del documento de identidad
							</p>
							<el-upload ref="adjuntos" class="upload-demo w-100" action="#" :multiple="false" :limit="1"
								:on-change="agregar_archivos" :auto-upload="false" :on-remove="handleRemove"
								:file-list="fileList">
								<div class="upload-documents w-100 text-center py-3 bg-light-f5 br-5 cr-pointer pt-1">
									Subir PDF o Imagen
								</div>
							</el-upload>
						</template>
					</div>
					<div class="col-1" />
					<div class="col-5">
						<!-- <div class="row mx-0 justify-center"> -->
						<div class="label-mini pl-0 mb-3">
							Asignar centro de distribución
						</div>
						<div class="col mb-4">
							<ValidationProvider v-slot="{ errors }" rules="required" name="cedis">
								<el-select v-model="model.id_cedis" filterable size="small" class="w-100 br-12">
									<el-option v-for="item in selectCedis" :key="item.id" :label="item.nombre"
										:value="item.id" />
								</el-select>
								<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
							</ValidationProvider>
						</div>
						<!-- </div> -->
						<p class="label-mini pl-0 mb-3">
							¿Dónde estás ubicado?
						</p>
						<p class="label-mini">
							País
						</p>
						<ValidationProvider v-slot="{ errors }" rules="required" name="país">
							<el-select v-model="model.idm_pais" placeholder="País" class="w-100 br-12"
								@change="listarEstado(model.idm_pais, 1)">
								<el-option v-for="(pais, indexp) in paises" :key="indexp" :label="pais.nombre"
									:value="pais.id" />
							</el-select>
							<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<p class="label-mini mt-4">
							Estado
						</p>
						<ValidationProvider v-slot="{ errors }" rules="required" name="estado">
							<el-select v-model="model.idm_estado" placeholder="Estado" class="w-100 br-12"
								@change="listarCiudad(model.idm_estado, 1)">
								<el-option v-for="(estado, indexe) in estados" :key="indexe" :label="estado.estado"
									:value="estado.id" />
							</el-select>
							<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<p class="label-mini mt-4">
							Ciudad
						</p>
						<ValidationProvider v-slot="{ errors }" rules="required" name="ciudad">
							<el-select v-model="model.idm_ciudad" placeholder="Ciudad" class="w-100 br-12">
								<el-option v-for="(ciudad, indexc) in ciudades" :key="indexc" :label="ciudad.nombre"
									:value="ciudad.id" />
							</el-select>
							<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<p class="label-mini mt-4">Dirección</p>
						<ValidationProvider v-slot="{ errors }" rules="required" name="dirección">
							<el-select v-model="model.direccion" filterable remote class="w-100 br-12" reserve-keyword
								:remote-method="remoteMethod" @change="getDetails()">
								<el-option v-for="item in options" :key="item.place_id" :label="item.description"
									:value="item.description" />
							</el-select>
							<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<p class="label-mini mt-4">Código postal (Opcional)</p>
						<ValidationProvider v-slot="{ errors }" rules="max:6|numeric" name="código postal">
							<el-input v-model="model.codigo_postal" maxlength="40" class="w-100 br-12" />
							<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<div class="row mx-0 my-3">
							<div class="col-12 px-0 my-2">
								<mapa ref="mapaPeq" class="br-20" :coordenadas="coordenadas" :buscar="model.direccion"
									@actualizar="actualizarCoordenadas" />
							</div>
						</div>
						<p class="label-mini mt-4">Barrio / Colonia (Opcional)</p>
						<ValidationProvider v-slot="{ errors }" vid="barrio" rules="max:30" name="barrio / colonia">
							<el-input v-model="model.barrio" maxlength="30" class="w-100 br-12" />
							<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<p class="label-mini mt-4">Cobertura (Metros)</p>
						<ValidationProvider v-slot="{ errors }" vid="cobertura" rules="numeric|min:0|max_value:65535|required"
							name="cobertura">
							<el-input v-model="model.cobertura" maxlength="30" class="w-100 br-12" />
							<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<p class="label-mini mt-4">Piso, apartamento o local (Opcional)</p>
						<ValidationProvider v-slot="{ errors }" rules="max:40" name="complemento">
							<el-input v-model="model.direccion_anexo" maxlength="40" class="w-100 br-12" />
							<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<p class="label-mini mt-4">Guardar como</p>
						<ValidationProvider v-slot="{ errors }" rules="required|max:30" vid="guardar_como"
							name="guardar como">
							<el-input v-model="model.guardar_como" maxlength="30" class="w-100 br-12" />
							<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
						</ValidationProvider>
						<p class="label-mini mt-4">Contraseña</p>
						<ValidationProvider v-slot="{ errors }" rules="required|max:200|" name="contraseña"
							vid="confirmation">
							<el-input v-model="model.password" class="w-100 br-12" type="password" maxlength="201"
								@change="cambioPassword" />
							<p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
						</ValidationProvider>
						<p class="label-mini mt-4">Confirmar contraseña</p>
						<ValidationProvider v-slot="{ errors }" rules="required|max:200|confirmed:confirmation"
							name="confirmar contraseña">
							<el-input v-model="model.password_confirmation" class="w-100 br-12" type="password"
								maxlength="201" @change="cambioPassword" />
							<p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
						</ValidationProvider>
					</div>
				</div>
			</ValidationObserver>
		</modal>
		<mapa-google-modal ref="modalMapaGoogle" busqueda postal :buscar="model.direccion"
			:coordenadas-entrada="coordenadas" @actualizar="actualizarCoordenadasCiudad" @cancelar="cancelarMapa()" />
		<modal-no-direccion ref="modalNoDireccion" @change="onChangeModal" @onClose="onClose()" />
	</section>
</template>

<script>
import DatosTienda from "~/services/configurar/tendero-vip/datos_tienda";
import Tienda from "~/services/configurar/tendero";
import { Money } from 'v-money'
import localizacion from '~/services/localizacion';

export default {
	components: {
		Money,
	},
	data() {
		return {
			slimOptions: {
				ratio: '1:1',
				label: 'Subir imagen de perfil',
				buttonCancelLabel: 'Cancelar',
				buttonConfirmLabel: 'ok'
			},
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() > Date.now()
				},
			},
			existTienda: false,
			cargando: false,
			model: {
				id_cedis: null,
				guardar_como: null,
				descripcion: null,
				descripcion_mi: null,
				direccion: null,
				direccion_anexo: null,
				barrio: null,
				cupo: 0,
				estado: null,
				id: null,
				idm_ciudad: null,
				idm_estado: null,
				ilimitado: false,
				idm_pais: null,
				latitud: 0,
				logo: null,
				logo_mini: null,
				longitud: 0,
				nit: null,
				nit_soporte: null,
				nombre: null,
				codigo_postal: null,
				tipo: 1,
				id_direccion: null,
				prop_nombre: null,
				prop_apellido: null,
				correo: null,
				telefono: null,
				cobertura: null,
				propietario_nombre: null,
				propietario_genero: 1,
				propietario_nacimiento: null,
				tienda: false,
				password: '',
				password_confirmation: ''
			},
			money: {
				thousands: '.',
				prefix: '$ ',
				precision: 0,
				masked: false
			},
			coordenadas: {
				lat: 0,
				lng: 0,
			},
			archivo: [],
			fileList: [],
			options: [],
			location: {
				city: '',
				state: '',
				country: '',
				postalCode: '',
				direction: '',
			},
			googleMap: null,
			cambioPass: 0,
			value: '',
			paises: [],
			estados: [],
			ciudades: [],
		}
	},
	computed: {
		selectCedis() {
			return this.cedis_calculo.filter(o => o.id != 0)
		}
	},
	methods: {
		async toggle() {
			this.clearData()
			this.handleRemove()
			this.listarPais()
			this.$refs.validacion.reset()
			this.$refs.modalCrearVendedor.toggle();

			await this.remoteMethod(this.model.direccion)
			await this.getDetails()
		},
		async getLocationFromBrowser() {
			try {
				let googleMaps = this.googleMap ?? await this.$iniciarGoogleMaps();
				this.location = await localizacion.getLocationFromBrowser(googleMaps);
			} catch (error) {
				this.error_catch(error)
			}
		},
		async getDetails() {
			let { place_id = '' } = this.options.find(el => el.description == this.model.direccion) ?? {}

			if (!place_id) return


			if (place_id == 'no_direccion') {
				this.onClose()
				return
			}

			let googleMaps = this.googleMap ?? await this.$iniciarGoogleMaps();
			let geocoder = new googleMaps.Geocoder();

			geocoder.geocode({
				'placeId': place_id
			},
				async (responses, status) => {
					if (status == 'OK') {
						let lat = responses[0].geometry.location.lat();
						let lng = responses[0].geometry.location.lng();
						this.coordenadas = { lat, lng }
						this.$refs.mapaPeq.setPosition()
						this.model.latitud = lat
						this.model.longitud = lng

						const obj = await localizacion.getLocationFromLatLng(googleMaps, lat, lng);
						this.model.codigo_postal = +obj.postalCode
						this.model.ciudad = obj.city
						this.model.departamento = obj.state
						this.model.pais = obj.country
					}
				});
		},
		handleRemove(file = null, fileList = null) {
			this.archivo = []
			this.fileList = []
			if(this.existTienda){
				this.$refs.adjuntos.clearFiles()
			}
		},
		cambioPassword() {
			this.cambioPass = 1
		},
		async agregar_archivos(file, fileList) {
			let nombreMayor100Carecteres = file.name.length > 100
			let tipo = null
			let tamano = null
			if (file.raw.type === 'application/pdf') {
				tipo = 1
				tamano = 20
			} else if (file.raw.type.split('/').slice(0, 1).pop() === 'image') {
				tipo = 2
				tamano = 1000
			} else if (file.raw.type === 'video/mp4') {
				tipo = 3
				tamano = 20
			} else {
				tipo = 4
				tamano = 25
			}

			const limite = file.size / 1024 / 1024 <= tamano
			if (!limite) {
				fileList.splice(fileList.indexOf(file), 1)
				await this.notificacion('No válido', 'El archivo no cumple los requisitos', 'warning')
				return
			}

			if (tipo == 3 || tipo == 4) {
				fileList.splice(fileList.indexOf(file), 1)
				await this.notificacion('No válido', 'El archivo no cumple con la extensión requerida(pdf ó imagenes)', 'warning')
				return
			}

			if (nombreMayor100Carecteres) {
				fileList.splice(fileList.indexOf(file), 1)
				await this.notificacion('Error', `¡El nombre del archivo ${file.name} excede los 100 caracteres!`, 'error')
				return
			}

			this.archivo.push(file.raw)
		},
		async updateDatos() {
			try {
				const valid = await this.$refs.validacion.validate()
				if (!valid) {
					this.notificacion('Mensaje', 'campos obligatorios', 'warning')
					return false
				}
				this.cargando = true

				const archivo = this.$refs.foto_cedis.instanciaCrop.dataBase64.output;
				this.model.logo = archivo.image
				this.model.logo_mini = null
				this.model.tipoAdmin = 1
				this.model.propietario_nombre = `${this.model.prop_nombre} ${this.model.prop_apellido}`
				this.model.id_tienda = this.$route.params.id_tienda

				if (this.archivo.length > 0) {
					this.model.nit_soporte = this.archivo[0]
				}

				let formData = this.crear_formData(this.model)

				const { data } = await Tienda.postTenderoManual(formData)
				this.notificacion('Mensaje', data.mensaje, 'success')
				this.$emit('actualizar')
				this.$refs.modalCrearVendedor.toggle();
			} catch (e) {
				this.error_catch(e)
			} finally {
				this.cargando = false
			}
		},
		abrirModalMapa() {
			this.$refs.modalCrearVendedor.toggle();
			this.$refs.modalMapaGoogle.toggle();
		},
		cancelarMapa() {
			this.$refs.modalCrearVendedor.toggle();
		},
		async actualizarCoordenadasCiudad({ latitud, longitud }) {
			this.$refs.modalCrearVendedor.toggle();
			this.model.latitud = latitud
			this.model.longitud = longitud
			let googleMaps = this.googleMap ?? await this.$iniciarGoogleMaps();

			const obj = await localizacion.getLocationFromLatLng(googleMaps, latitud, longitud);
			this.coordenadas.lat = +this.model.latitud
			this.coordenadas.lng = +this.model.longitud
			this.model.codigo_postal = +obj.postalCode
			this.model.direccion = obj.direction
			this.model.ciudad = obj.city
			this.model.departamento = obj.state
			this.model.pais = obj.country
			this.$refs.mapaPeq.setPosition()
			this.options = []

		},
		actualizarCoordenadas({ latitud, longitud }) {
			this.model.latitud = latitud
			this.model.longitud = longitud
		},
		buscarDireccion() {
			this.$refs.mapaPeq.accionBuscar();
		},
		async remoteMethod(query) {
			try {
				let googleMaps = await this.$iniciarGoogleMaps();
				this.options = await localizacion.getPlaces(googleMaps, query);
				this.options.push({ place_id: 'no_direccion', description: 'No aparece mi dirección' })
			} catch (e) {
				this.options = [];
				this.options.push({ place_id: 'no_direccion', description: 'No aparece mi dirección' })
			}
		},
		async onChangeModal(direccion) {
			this.onClose()
			this.model.direccion = direccion
			await this.remoteMethod(direccion)
			await this.getDetails()
		},
		onClose() {
			this.$refs.modalNoDireccion.toggle()
			this.$refs.modalCrearVendedor.toggle();
		},
		async listarPais() {
			try {
				const { data } = await DatosTienda.getPaises(this.$usuario.proyecto.id)
				this.paises = data.data
			} catch (e) {
				this.error_catch(e)
			}
		},
		async listarEstado(pais = this.model.idm_pais, cambio) {
			try {
				const { data } = await DatosTienda.getEstados(this.$usuario.proyecto.id, pais)
				this.estados = data.data
				if (cambio == 1) {
					this.model.idm_estado = null
					this.model.idm_ciudad = null
				}

			} catch (e) {
				this.error_catch(e)
			}
		},
		async listarCiudad(estado = this.model.idm_estado, cambio) {
			try {
				const { data } = await DatosTienda.getCiudades(this.$usuario.proyecto.id, estado)
				this.ciudades = data.data
				if (cambio == 1) {
					this.model.idm_ciudad = null
				}
			} catch (e) {
				this.error_catch(e)
			}
		},
		esIlimitado() {
			if (this.model.ilimitado) {
				return this.model.cupo = 0;
			}
		},
		clearData() {
			this.model = {
				id_cedis: null,
				guardar_como: null,
				descripcion: null,
				descripcion_mi: null,
				direccion: null,
				direccion_anexo: null,
				barrio: null,
				cupo: 0,
				estado: null,
				id: null,
				idm_ciudad: null,
				idm_estado: null,
				ilimitado: false,
				idm_pais: null,
				latitud: 0,
				logo: null,
				logo_mini: null,
				longitud: 0,
				nit: null,
				nit_soporte: null,
				nombre: null,
				codigo_postal: null,
				tipo: 1,
				id_direccion: null,
				prop_nombre: null,
				prop_apellido: null,
				correo: null,
				telefono: null,
				cobertura: null,
				propietario_nombre: null,
				propietario_genero: 1,
				propietario_nacimiento: null,
				tienda: false,
				password: '',
				password_confirmation: ''
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.border-separador {
	border: 2px solid var(--text-general) !important;
}

.leechero-picture {
	height: 150px;
	width: 150px;
	background-color: #FAFAFA;
	border-radius: 8px;
}

.upload-documents {
	border: 1px dashed var(--color-general2);
}

.label-mini {
	padding-left: 15px;
	color: #000000;
	font-size: 14px;
}
</style>
